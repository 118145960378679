import axios from 'axios'
import Util from "../../Util.js"

var BUYER_BACKEND_URL = process.env.VUE_APP_BUYER_BACKEND_URL
var ADMIN_BACKEND_URL = process.env.VUE_APP_ADMIN_BACKEND_URL

export default class RestResource {
    register(user) {
        const url = `${BUYER_BACKEND_URL}/auth/register`
        return axios.post(url, user, Util.setupHeaders());
    }

    updateStatus(project) {
        const url = `${BUYER_BACKEND_URL}/admin/updateProjectStatus`
        return axios.post(url, project, Util.setupHeaders())
    }

    getProjectConfigs() {
        const url = `${BUYER_BACKEND_URL}/admin/getCreateProjectConfigs`
        return axios.get(url, Util.setupHeaders())
    }

    reuploadProject(project) {
        const url = `${BUYER_BACKEND_URL}/admin/respondentReupload`
        return axios.post(url, project, Util.setupHeaders())
    }

    uploadSurveyParameters(data) {
        const url = `${BUYER_BACKEND_URL}/admin/uploadSurveyParameters`
        return axios.post(url, data, Util.setupHeaders())
    }

    getRespondentCount(criteria){
        const url = `${BUYER_BACKEND_URL}/respondent/getRespondentCount`
        return axios.post(url, criteria, Util.setupHeaders())
    }

    fetchRespondent(params) {
        const url = `${BUYER_BACKEND_URL}/respondent/fetchRespondent`
        return axios.post(url, params, Util.setupHeaders());
    }

    disqualifyRespondent(respondents) {
        const url = `${BUYER_BACKEND_URL}/respondent/disqualifyRespondents`
        return axios.post(url, respondents, Util.setupHeaders());
    }

    fetchRespondents(searchCriteria) {
        const url = `${BUYER_BACKEND_URL}/respondent/fetchRespondents`
        return axios.post(url, searchCriteria, Util.setupHeaders());
    }

    fetchUsers(params) {
        const url = `${BUYER_BACKEND_URL}/user/fetchUsers`
        return axios.post(url, params, Util.setupHeaders());
    }

    deleteUser(params) {
        const url = `${BUYER_BACKEND_URL}/user/deleteUser` 
        return axios.delete(url, params, Util.setupHeaders());
    }

    updateUser(user) {
        const url = `${BUYER_BACKEND_URL}/user/updateUser`
        return axios.post(url, user, Util.setupHeaders());
    }

    fetchProjects(params = {}) {
        const url = `${BUYER_BACKEND_URL}/project/fetchProjects`
        return axios.post(url, params, Util.setupHeaders());
    }

    fetchProject(params) {
        const url = `${BUYER_BACKEND_URL}/project/fetchProject`
        return axios.post(url, params, Util.setupHeaders());
    }

    updateProject(project) {
        const url = `${BUYER_BACKEND_URL}/project/updateProject`
        return axios.post(url, project, Util.setupHeaders());
    }
    
    createProject(project) {
        const url = `${BUYER_BACKEND_URL}/project/createProject`
        return axios.post(url, project, Util.setupHeaders());
    }

    getConfig(key) {
        const url = `${ADMIN_BACKEND_URL}/common/data/getConfig?key=${key}`
        return axios.get(url)
    }

    deleteRespondents(deleteCriteria) {
        const url = `${BUYER_BACKEND_URL}/responses/delete`
        return axios.post(url, deleteCriteria)
    }

    syncRespondents(syncCriteria) {
        const url = `${BUYER_BACKEND_URL}/responses/getAndSave`
        return axios.post(url, syncCriteria)
    }

    getProjectsForUser(data) {
        const url = `${BUYER_BACKEND_URL}/project/getProjectsForUser`
        return axios.post(url, data, Util.setupHeaders());
    }

    // Possible to merge with the two methods below?
    deleteProjectsInUsers(data) {
        const url = `${BUYER_BACKEND_URL}/project/deleteProjectsInUsers`
        return axios.post(url, data, Util.setupHeaders());
    }

    // Refactor to merge with the method below
    addProjectToUser(data) {
        const url = `${BUYER_BACKEND_URL}/project/addProjectToUser`
        return axios.post(url, data, Util.setupHeaders())
    }

    // Refactor to merge with the method above
    assignProjectsToUser(data) {
        const url = `${BUYER_BACKEND_URL}/project/assignProjectsToUsers`
        return axios.post(url, data, Util.setupHeaders())
    }

    fetchOrganization(data) {
        const url = `${ADMIN_BACKEND_URL}/common/organization/fetchOrganization`
        return axios.post(url, data, Util.setupHeaders())
    }

    fetchOrganizations(data) {
        const url = `${ADMIN_BACKEND_URL}/common/organization/fetchOrganizations`
        return axios.post(url, data, Util.setupHeaders())
    }

    fetchQuotations() {
        const url = `${BUYER_BACKEND_URL}/api/getQuotations`
        return axios.post(url, {}, Util.setupHeaders())
    }
    
    fetchQuotation(quotationId) {
        const url = `${BUYER_BACKEND_URL}/api/getQuotation`
        return axios.post(url, { quotationId }, Util.setupHeaders())
    }

    uploadFile(data) {
        const url = `${BUYER_BACKEND_URL}/project/uploadFile`
        return axios.post(url, data, Util.setupHeaders())
    }

    deleteFile(data) {
        const url = `${BUYER_BACKEND_URL}/project/deleteFile`
        return axios.post(url, data, Util.setupHeaders())
    }

    getProjectFiles(data) {
        const url = `${BUYER_BACKEND_URL}/project/projectFiles`
        return axios.post(url, data, Util.setupHeaders())
    }

    surveyGizmoProxyPass(params) {        
        const url = `${ADMIN_BACKEND_URL}/proxyPass/surveygizmo`
        return axios.post(url, params, Util.setupHeaders())
    }
}