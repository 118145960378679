
<template>
  <v-card>
    <v-card-title>
      Buyer User Management
      <v-spacer></v-spacer>
      <v-text-field
        class="pr-4"
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
     <v-btn to="createUser" color="primary" id="bt1" dark class="mb-2 mt-2">Create User</v-btn>
    </v-card-title>

    <v-data-table :headers="headers" :items="users"  :search="search">
      <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="$router.push({ path: `/buyer/${item.userId}/edit` })">edit</v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import RestResource from "@/services/dataServiceBuyer.js";
const service = new RestResource();
export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "Role", value: "roles", sortable: true },
        { text: "Actions", value: "actions", sortable: false }
      ],
      valid: true,
      users: [],
      editedIndex: -1,
      editedItem: {}
    };
  },
  mounted() {
    this.loadUsers();
  },
  methods: {
    loadUsers() {
      this.$setLoader();
      service.fetchUsers().then(r => {
        this.users = r.data;
        this.$disableLoader();
      });
    },

    // async deleteUser(userId) {
    //   if (confirm("Are you sure you want to delete user")) {
    //     service.deleteUser({ userId }).then(() => {
    //       alert("Successfully deleted.");
    //       this.loadUsers();
    //     });
    //   }
    // }
  },
};
</script>